<template>
  <div>
    <div :id="funnelId"></div>
  </div>
</template>

<script>
import D3Funnel from "d3-funnel";

export default {
  name: "FunnelChart",
  props: {
    dataset: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
    colorScheme: {
      type: Array,
      required: false,
    },
    id: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      defaultOptions: {
        chart: {
          height: "150",
          width: "150",
        },
        block: {
          fill: {
            scale: this.colorScheme
              ? this.colorScheme
              : ["#2c01c4", "#D60000", "#31b156"],
          },
        },
        tooltip: {
          enabled: true,
          format: "{l}",
        },
        label: {
          format: (label, value) => {
            return this.$filters.numberThousandSeparator(value);
          },
        },
      },
    };
  },
  mounted() {
    this.drawFunnel();
  },
  watch: {
    dataset() {
      this.drawFunnel();
    },
    options() {
      this.drawFunnel();
    },
    colorScheme() {
      this.drawFunnel();
    },
  },
  computed: {
    funnelId() {
      return this.id ? this.id : "funnel";
    },
  },
  methods: {
    drawFunnel() {
      const data = this.dataset;
      const options = this.options
        ? { ...this.defaultOptions, ...this.options }
        : this.defaultOptions;

      const chart = new D3Funnel("#" + this.funnelId);
      chart.draw(data, options);
    },
  },
};
</script>
