<template>
  <div class="p-3">
    <div
      class="surface-card shadow-2 p-2 border-1 border-50 border-round grid mt-1 flex"
    >
      <div class="col-12">
        <h2>{{ campaign.name }}</h2>
      </div>

      <div class="col-12 lg:col-4">
        <div class="grid mx-1 h-100">
          <div class="col-12 text-black-alpha-40 text-center">
            Response Funnel
          </div>
          <div
            class="mt-3 col-12 text-center"
            style="height: 100%"
            v-if="responseFunnelData"
          >
            <FunnelChart
              :dataset="responseFunnelData"
              :id="'cam' + campaign.id"
              :options="{
                chart: {
                  height: '200',
                  width: '250',
                },
              }"
              :color-scheme="colorScheme"
            />
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4">
        <div class="mx-1 grid h-100">
          <div class="col-12 text-black-alpha-40 text-center">Source</div>

          <div class="col-12 mt-2">
            <Chart
              type="pie"
              ref="deviceChart"
              :data="sourceChartData"
              :options="chartOptions"
              height="200px"
              width="200px"
            />
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4">
        <div
          class="flex flex-column justify-content-center align-items-center"
          style="height: 100%"
        >
          <div>
            <Button label="VIEW CAMPAIGN" @click="setCampaign" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignOverview from "@/models/CampaignOverview";
import FunnelChart from "@/components/FunnelChart";
import { useAppStore } from "@/stores/app";
export default {
  name: "CampaignSelectorItem",
  components: { FunnelChart },
  setup() {
    const appStore = useAppStore();

    return { appStore };
  },
  data() {
    return {
      campaignOverview: null,
      loading: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      sourceChartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#4f6980", "#849db1"],
            hoverBackgroundColor: ["#64B5F6", "#81C784"],
          },
        ],
      },
      dataset: "web_visits",
    };
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadCampaign();
  },
  computed: {
    responseFunnelData() {
      if (!this.campaignOverview) return [{ label: "", value: 0 }];

      let funnelData = [];

      funnelData.push({
        label: "Mailed",
        value: parseInt(this.campaignOverview.mailed.total),
      });
      if (this.campaignOverview.delivered.total)
        funnelData.push({
          label: "Delivered",
          value: this.campaignOverview.delivered.total,
        });
      funnelData.push({
        label: "Responded",
        value: this.campaignOverview.web_visits.total,
      });
      funnelData.push({
        label: "Leads",
        value: this.campaignOverview.web_leads.total,
      });

      return funnelData;
    },
    colorSchemeFromStore() {
      return this.appStore.colorScheme;
    },
    colorScheme() {
      if (this.colorSchemeFromStore) {
        return this.colorSchemeFromStore;
      } else {
        return ["#4f6980", "#afbc36", "#a2ceaa", "#638b66"]; // TODO: Return default color scheme
      }
    },
    colorSchemeHover() {
      let colorScheme = this.colorSchemeFromStore
        ? this.colorSchemeFromStore
        : [];
      return colorScheme.map((color) => this.adjustColorBrightness(color, -30));
    },
  },
  methods: {
    loadCampaign() {
      this.loading = true;
      CampaignOverview.find(this.campaign.id)
        .then((res) => {
          this.campaignOverview = res.data;

          this.setChartData(this.campaignOverview);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong. Please Try Again",
            life: 2000,
          });
        })
        .then(() => (this.loading = false));
    },
    getMap(arr, key) {
      return arr.reduce(
        (entryMap, e) =>
          entryMap.set(e[key], [...(entryMap.get(e[key]) || []), e]),
        new Map()
      );
    },
    getLabelsAndValues(arr, key) {
      const grouped = this.getMap(arr, key);

      const labels = [];
      const values = [];

      grouped.forEach((value, key) => {
        labels.push(key.toUpperCase());
        values.push(value.reduce((a, b) => a + (b["agg"] || 0), 0));
      });

      return {
        labels: labels,
        values: values,
      };
    },
    setChartData(campaignData) {
      let result;

      // Source data
      result = this.getLabelsAndValues(
        campaignData[this.dataset].breakdown,
        "initial_response_channel"
      );

      this.sourceChartData.labels = result.labels;
      this.sourceChartData.datasets[0].data = result.values;
      this.sourceChartData.datasets[0].backgroundColor = this.colorScheme;
      this.sourceChartData.datasets[0].hoverBackgroundColor =
        this.colorSchemeHover;
    },
    setCampaign() {
      this.appStore.setCurrentCampaign(this.campaign);
      this.$router.push({ name: "dashboard" });
    },
  },
};
</script>
